import React from "react";
import { NotFoundWrapper } from "./wrapper";
import { FourZeroFour, PageNotFound } from "./main-text";
import { HomeLink } from "./link";

export const NotFoundSection = () => {
  return (
    <NotFoundWrapper>
      <FourZeroFour>404</FourZeroFour>
      <PageNotFound>Page Not Found</PageNotFound>
      <HomeLink to="/">블로그 구경가기</HomeLink>
    </NotFoundWrapper>
  );
};
