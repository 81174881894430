import styled from "styled-components";
import { H2, Paragraph } from "../../common";

export const FourZeroFour = styled(H2)`
  font-size: ${({ theme }) => theme.fontSize.xl7};
  font-weight: ${({ theme }) => theme.fontWeight.bold};

  @media ${({ theme }) => theme.device.mobile} {
    font-size: ${({ theme }) => theme.fontSize.xl9};
  }
`;

export const PageNotFound = styled(Paragraph)`
  font-size: ${({ theme }) => theme.fontSize.xl2};
  font-weight: ${({ theme }) => theme.fontWeight.bold};

  margin-bottom: 30px;

  @media ${({ theme }) => theme.device.mobile} {
    font-size: ${({ theme }) => theme.fontSize.xl4};
  }
`;
