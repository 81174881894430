import styled from "styled-components";

export const NotFoundWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 40px;

  ${({ theme }) => theme.preventUserSelect};
`;
