import styled from "styled-components";

export const RecentPostsWrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;

  ${({ theme }) => theme.preventUserSelect};
`;
