import React from "react";
import { NotFoundSection } from "../components/404/not-found";
import { RecentPostsSection } from "../components/404/recent-posts";
import Layout from "../components/layout";
import SeoHelmet from "../components/seo";

const NotFoundPage = () => {
  return (
    <>
      <SeoHelmet title="404 Page Not Found" pathname="/404" />
      <Layout addDefaultMainAd={false}>
        <NotFoundSection />
        <RecentPostsSection />
      </Layout>
    </>
  );
};

export default NotFoundPage;
