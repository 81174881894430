import { Link } from "gatsby";
import styled from "styled-components";

export const HomeLink = styled(Link)`
  padding: 15px;

  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.fontSize.lg};
  line-height: ${({ theme }) => theme.lineHeight.normal};

  color: white;
  background-color: ${({ theme }) => theme.colors.thickHighlightColor};

  border-radius: 20px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.highlightColor};
  }
`;
