import styled from "styled-components";

export const RecentPostsContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin: 0px 20px;

  border: 1px solid ${({ theme }) => theme.colors.grayBorder};
  border-radius: 15px;
`;
