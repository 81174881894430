import React from "react";
import { RecentPosts } from "../../common/recent-posts/posts";
import { RecentPostsContainer } from "./container";
import { RecentPostsWrapper } from "./wrapper";

export const RecentPostsSection = () => {
  return (
    <RecentPostsWrapper>
      <RecentPostsContainer>
        <RecentPosts />
      </RecentPostsContainer>
    </RecentPostsWrapper>
  );
};
